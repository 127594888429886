/* eslint-disable react/jsx-filename-extension */
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import PreparationCoursePage from '../../containers/PreparationCoursePage';

const ColegioMilitarPreSextoAno = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      colegioMilitarPre6AnoJson {
        hero {
          title
          subtitle
          description
          backgroundImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          mobileBackgroundImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 601, maxHeight: 715, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          action {
            label
            link
          }
        }
        faq {
          title
          subtitle
          videoLink
          videoImage {
            childImageSharp {
              fluid(maxWidth: 570, maxHeight: 350, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image1 {
            childImageSharp {
              fluid(maxWidth: 310, maxHeight: 190, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 188, maxHeight: 115, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 188, maxHeight: 115, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image4 {
            childImageSharp {
              fluid(maxWidth: 190, maxHeight: 190, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          items {
            title
            content
          }
        }
        teachers
        benefits {
          title
          items
          primaryAction {
            label
            link
          }
        }
      }
    }
  `);

  const courseData = data.colegioMilitarPre6AnoJson;

  return (
    <PreparationCoursePage
      title="Curso Preparatório para Colégio Militar Garra - Pré 6º Ano"
      description="Seu filho pode sim ser aprovado em colégios militares em Curitiba. A melhor preparação para o CPM e CMC está aqui no Garra. Saiba mais!"
      location={location}
      data={courseData}
    />
  );
};

ColegioMilitarPreSextoAno.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ColegioMilitarPreSextoAno;
